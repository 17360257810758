import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import screenfull from "screenfull";
export default {
  name: "Screenfull",
  props: {
    width: {
      type: Number,
      default: 22
    },
    height: {
      type: Number,
      default: 22
    },
    fill: {
      type: String,
      default: "#48576a"
    }
  },
  data: function data() {
    return {
      isFullscreen: false
    };
  },
  methods: {
    click: function click() {
      if (!screenfull.isEnabled) {
        this.$message({
          message: "The component does not work with your browser",
          type: "warning"
        });
        return false;
      }

      screenfull.toggle();
    }
  }
};