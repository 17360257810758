//
//
//
//
//
//
//
import { isExternal } from "@/utils";
export default {
  props: {
    to: {
      type: String,
      required: true
    }
  },
  methods: {
    isExternalLink: function isExternalLink(routePath) {
      return isExternal(routePath);
    },
    linkProps: function linkProps(url) {
      if (this.isExternalLink(url)) {
        return {
          is: "a",
          href: url,
          target: "_blank",
          rel: "noopener"
        };
      }

      return {
        is: "router-link",
        to: url
      };
    }
  }
};