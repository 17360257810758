import request from "@/utils/request";
export function loginByUsername(email, password) {
  var data = {
    email: email,
    password: password,
    strategy: "local"
  };
  return request({
    url: "/authentication",
    method: "post",
    data: data
  });
}
export function logout() {
  return request({
    url: "/login/logout",
    method: "post"
  });
}
export function getUserInfo(id) {
  return request({
    url: "/users/" + id,
    method: "get"
  });
}