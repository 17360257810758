import "core-js/modules/es.object.to-string";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
/* Layout */

import Layout from '@/views/layout/Layout';
/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

export var constantRouterMap = [{
  path: '/about',
  component: Layout,
  hidden: false,
  children: [{
    path: '/about',
    component: function component() {
      return import('@/views/About');
    }
  }]
}, {
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/forgot',
  component: function component() {
    return import('@/views/login/resetpassword');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/authredirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/errorPage/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/errorPage/401');
  },
  hidden: true
}, {
  path: '',
  component: Layout,
  redirect: 'dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      icon: 'dashboard',
      noCache: true
    }
  }, {
    path: 'compliance-editor',
    component: function component() {
      return import('@/views/dashboard/compliance/editor');
    },
    hidden: true,
    name: 'Compliance Order Editor',
    meta: {
      title: 'compliance-editor',
      noCache: true,
      roles: ['compliance officer']
    }
  }, {
    path: '/profile',
    component: function component() {
      return import('@/views/users/profile');
    },
    name: 'My Profile',
    hidden: true,
    meta: {
      title: 'profile',
      icon: 'peoples',
      noCache: true
    }
  }]
}];
export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});
export var asyncRouterMap = [{
  path: '/users',
  component: Layout,
  redirect: '/users/index',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/users/index');
    },
    name: 'Users',
    meta: {
      title: 'users',
      icon: 'peoples',
      noCache: true
    }
  }]
}, {
  path: '/organisations',
  component: Layout,
  redirect: '/organisations/index',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/organisations/index');
    },
    name: 'Organisations',
    meta: {
      title: 'Organisations',
      icon: 'tree',
      roles: ['admin'],
      noCache: true
    }
  }]
}, {
  path: '/wall-bay',
  component: Layout,
  name: 'Wall Bay',
  alwaysShow: true,
  meta: {
    title: 'wb',
    icon: 'crate',
    roles: ['product manager', 'sales manager']
  },
  children: [{
    path: 'graphics',
    component: function component() {
      return import('@/views/graphics/wallbay');
    },
    name: 'Wall Bay Graphics',
    meta: {
      title: 'wb-graphics',
      icon: 'image',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'graphics-new',
    hidden: true,
    component: function component() {
      return import('@/views/graphics/components/wb');
    },
    name: 'Wall Bay Graphic Editor',
    meta: {
      title: 'wb-graphic-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'products',
    component: function component() {
      return import('@/views/products/index');
    },
    name: 'Wall Bay Products',
    meta: {
      title: 'wb-products',
      icon: 'makeup',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'product-editor',
    hidden: true,
    component: function component() {
      return import('@/views/products/components/product');
    },
    name: 'Wall Bay Product Editor',
    meta: {
      title: 'wb-product-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'inserts',
    component: function component() {
      return import('@/views/inserts/index');
    },
    name: 'Accessories',
    meta: {
      title: 'accessories',
      icon: 'ice-cube-tray',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'insert-editor',
    hidden: true,
    component: function component() {
      return import('@/views/inserts/insert-editor');
    },
    name: 'Accessory Editor',
    meta: {
      title: 'accessory-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'bases',
    component: function component() {
      return import('@/views/bases/index');
    },
    name: 'Backgrounds',
    meta: {
      title: 'bases',
      icon: 'background',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'base-editor',
    hidden: true,
    component: function component() {
      return import('@/views/bases/editor');
    },
    name: 'Base Editor',
    meta: {
      title: 'base-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'components',
    component: function component() {
      return import('@/views/components/index');
    },
    name: 'Components',
    meta: {
      title: 'Components',
      icon: 'work-tray',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'components-new',
    hidden: true,
    component: function component() {
      return import('@/views/components/components/componentEditor');
    },
    name: 'Component Editor',
    meta: {
      title: 'component-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'trays',
    component: function component() {
      return import('@/views/trays/index');
    },
    name: 'Trays',
    meta: {
      title: 'trays',
      icon: 'data',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'trays-new',
    hidden: true,
    component: function component() {
      return import('@/views/trays/components/trayEditor');
    },
    name: 'Tray Editor',
    meta: {
      title: 'tray-editor',
      icon: 'data',
      noCache: true
    }
  }, {
    path: 'planograms',
    component: function component() {
      return import('@/views/planograms/index');
    },
    name: 'Wall Bays',
    meta: {
      title: 'wallbays',
      icon: 'construction-and-tools',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'planograms-new',
    hidden: true,
    component: function component() {
      return import('@/views/planograms/components/planogramEditor');
    },
    name: 'Wall Bay Editor',
    meta: {
      title: 'wallbay-editor',
      icon: 'component',
      noCache: true
    }
  }]
}, {
  path: '/counter-unit',
  component: Layout,
  name: 'Counter Unit',
  alwaysShow: true,
  meta: {
    title: 'cu',
    icon: 'graphic',
    roles: ['product manager', 'sales manager']
  },
  children: [{
    path: 'graphics',
    component: function component() {
      return import('@/views/graphics/counterunit');
    },
    name: 'Counter Unit Graphics',
    meta: {
      title: 'cu-graphics',
      icon: 'image',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'graphics-new',
    hidden: true,
    component: function component() {
      return import('@/views/graphics/components/cu');
    },
    name: 'Counter Unit Graphic Editor',
    meta: {
      title: 'cu-graphic-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'products',
    component: function component() {
      return import('@/views/counter-unit/tile-products');
    },
    name: 'Counter Unit Products',
    meta: {
      title: 'cu-products',
      icon: 'makeup',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'tile-product-editor',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/components/tile-product-editor');
    },
    name: 'Counter Unit Product Editor',
    meta: {
      title: 'cu-product-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'tiles-bases',
    component: function component() {
      return import('@/views/counter-unit/tile-bases');
    },
    name: 'Tile Bases',
    meta: {
      title: 'tilebases',
      icon: 'business',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'tile-base-editor',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/components/tile-base-editor');
    },
    name: 'Tile Base Editor',
    meta: {
      title: 'tilebase-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'tiles',
    component: function component() {
      return import('@/views/counter-unit/tiles');
    },
    name: 'Tiles (Simple)',
    meta: {
      title: 'tiles-s',
      icon: 'makeupkit',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'tile-simple-editor',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/components/tile-simple-editor');
    },
    name: 'Tile (Simple) Editor',
    meta: {
      title: 'tiles-s-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'tiles-complex',
    component: function component() {
      return import('@/views/counter-unit/tiles-complex');
    },
    name: 'Tiles (Complex)',
    meta: {
      title: 'tiles-c',
      icon: 'makeupkit',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'tile-complex-editor',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/components/tile-complex-editor');
    },
    name: 'Tile (Complex) Editor',
    meta: {
      title: 'tiles-c-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'shelves',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/shelves');
    },
    name: 'Shelves',
    meta: {
      title: 'shelves',
      icon: 'icon',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'shelve-editor',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/components/shelve-editor');
    },
    name: 'Shelve Editor',
    meta: {
      title: 'shelve-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'shelves-2',
    hidden: true,
    component: function component() {
      return import('@/views/counter-unit/shelves-2');
    },
    name: 'Shelves-2',
    meta: {
      title: 'shelves-2',
      icon: 'icon',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'planograms',
    component: function component() {
      return import(
      /* webpackChunkName: "cu-planogram" */
      '@/views/counter-unit/planograms');
    },
    name: 'CU 1',
    meta: {
      title: 'cu1',
      icon: 'size',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'planograms-new',
    hidden: true,
    component: function component() {
      return import(
      /* webpackChunkName: "cu-planogram" */
      '@/views/shelves/index');
    },
    props: function props(route) {
      return {
        id: route.query.id
      };
    },
    name: 'Counter Unit 1 Editor',
    meta: {
      title: 'cu1-editor',
      icon: 'component',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'planograms-2',
    component: function component() {
      return import(
      /* webpackChunkName: "cu-planogram" */
      '@/views/counter-unit/planograms-2');
    },
    name: 'CU 2',
    meta: {
      title: 'cu2',
      icon: 'filing',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'planograms-new-2',
    hidden: true,
    component: function component() {
      return import(
      /* webpackChunkName: "cu-planogram" */
      '@/views/shelves/index-2');
    },
    props: function props(route) {
      return {
        id: route.query.id
      };
    },
    name: 'Counter Unit 2 Editor',
    meta: {
      title: 'cu2-editor',
      icon: 'forms',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }]
}, {
  path: '/stores',
  component: Layout,
  name: 'Store',
  alwaysShow: true,
  meta: {
    title: 'sr',
    icon: 'warehouse',
    roles: ['product manager', 'sales manager']
  },
  children: [{
    path: 'graphics',
    component: function component() {
      return import('@/views/graphics/showroom');
    },
    name: 'Store Graphics',
    meta: {
      title: 'sr-graphics',
      icon: 'image',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'graphics-new',
    hidden: true,
    component: function component() {
      return import('@/views/graphics/components/sr');
    },
    name: 'Store Graphic Editor',
    meta: {
      title: 'sr-graphic-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'floors',
    component: function component() {
      return import('@/views/showrooms/floors');
    },
    name: 'Floors',
    meta: {
      title: 'floors',
      icon: 'parquet-floor',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'floor-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/components/floors');
    },
    name: 'Floor Editor',
    meta: {
      title: 'floor-editor',
      icon: 'table',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'boxes',
    component: function component() {
      return import('@/views/showrooms/boxes');
    },
    name: 'Boxes',
    meta: {
      title: 'boxes',
      icon: 'box',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'box-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/components/boxes');
    },
    name: 'Box Editor',
    meta: {
      title: 'box-editor',
      icon: 'table',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'props',
    component: function component() {
      return import('@/views/showrooms/props');
    },
    name: 'Props',
    meta: {
      title: 'props',
      icon: 'column',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'prop-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/components/props');
    },
    name: 'Prop Editor',
    meta: {
      title: 'prop-editor',
      icon: 'table',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'products',
    component: function component() {
      return import('@/views/showrooms/product-index');
    },
    name: 'Store Products',
    meta: {
      title: 'sr-products',
      icon: 'vacuum',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'product-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/components/product');
    },
    name: 'Store Product Editor',
    meta: {
      title: 'sr-product-editor',
      icon: 'table',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'plinths',
    component: function component() {
      return import('@/views/showrooms/plinth-base-index');
    },
    name: 'Plinths',
    meta: {
      title: 'plinths',
      icon: 'business',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'plinth-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/components/plinth-base');
    },
    name: 'Plinth Editor',
    meta: {
      title: 'plinth-editor',
      icon: 'table',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'plinth-builder',
    component: function component() {
      return import('@/views/showrooms/plinth-index');
    },
    name: 'Plinth Builder',
    meta: {
      title: 'plinth-builder',
      icon: 'plinth',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'plinth-builder-manager',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/plinth-manager');
    },
    meta: {
      roles: ['admin', 'product manager', 'sales manager'],
      title: 'plinth-builder-manager',
      icon: 'peoples'
    }
  }, {
    path: 'plinth-builder-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/plinth-editor');
    },
    meta: {
      roles: ['admin', 'product manager', 'sales manager'],
      title: 'plinth-builder-editor',
      icon: 'peoples'
    }
  }, {
    path: 'store-builder',
    component: function component() {
      return import('@/views/showrooms/showroom-index');
    },
    name: 'Store Builder',
    meta: {
      title: 'store-builder',
      icon: 'empire-state-building',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'store-manager',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/showroom-manager');
    },
    meta: {
      roles: ['admin', 'product manager', 'sales manager'],
      title: 'store-builder-manager',
      icon: 'peoples'
    }
  }, {
    path: 'store-editor',
    hidden: true,
    component: function component() {
      return import('@/views/showrooms/showroom-editor');
    },
    meta: {
      roles: ['admin', 'product manager', 'sales manager'],
      title: 'store-builder-editor',
      icon: 'peoples'
    }
  }]
}];
export var asyncGHDMap = [{
  path: '/users',
  component: Layout,
  redirect: '/users/index',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/users/index');
    },
    name: 'Users',
    meta: {
      title: 'users',
      icon: 'peoples',
      noCache: true
    }
  }]
}, {
  path: '/organisations',
  component: Layout,
  redirect: '/organisations/index',
  meta: {
    roles: ['admin']
  },
  children: [{
    path: '',
    component: function component() {
      return import('@/views/organisations/index');
    },
    name: 'Organisations',
    meta: {
      title: 'organisations',
      icon: 'tree',
      roles: ['admin'],
      noCache: true
    }
  }]
}, {
  path: '/wall-bay',
  component: Layout,
  name: 'Wall Bay',
  alwaysShow: true,
  meta: {
    title: 'wb',
    icon: 'crate',
    roles: ['product manager', 'sales manager']
  },
  children: [{
    path: 'graphics',
    component: function component() {
      return import('@/views/graphics/wallbay');
    },
    meta: {
      title: 'wb-graphics',
      icon: 'image',
      noCache: true,
      roles: ['product manager']
    },

    /* ghd */
    children: [{
      path: '',
      query: {
        tag: 'ghd core range'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'ghd core range',
      meta: {
        title: 'ghd core range',
        noCache: true,
        roles: ['product manager'],
        acl: 'ghd core range'
      }
    }, {
      path: '',
      query: {
        tag: 'AW limited edition'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'AW limited edition',
      meta: {
        title: 'AW limited edition',
        noCache: true,
        roles: ['product manager'],
        acl: 'AW limited edition'
      }
    }, {
      path: '',
      query: {
        tag: 'Pink limited edition'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'Pink limited edition',
      meta: {
        title: 'Pink limited edition',
        noCache: true,
        roles: ['product manager'],
        acl: 'Pink limited edition'
      }
    }, {
      path: '',
      query: {
        tag: 'Xmas limited edition'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'Xmas limited edition',
      meta: {
        title: 'Xmas limited edition',
        noCache: true,
        roles: ['product manager'],
        acl: 'Xmas limited edition'
      }
    }, {
      path: '',
      query: {
        tag: 'H1 NPD'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'H1 NPD',
      meta: {
        title: 'H1 NPD',
        noCache: true,
        roles: ['product manager'],
        acl: 'H1 NPD'
      }
    }, {
      path: '',
      query: {
        tag: 'H2 NPD'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'H2 NPD',
      meta: {
        title: 'H2 NPD',
        noCache: true,
        roles: ['product manager'],
        acl: 'H2 NPD'
      }
    }, {
      path: '',
      query: {
        tag: 'Season Sales'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'Season Sales',
      meta: {
        title: 'Season Sales',
        noCache: true,
        roles: ['product manager'],
        acl: 'Season Sales'
      }
    }, {
      path: '',
      query: {
        tag: 'Other'
      },
      //component: () => import('@/views/graphics/wallbay'),
      name: 'Other',
      meta: {
        title: 'Other',
        noCache: true,
        roles: ['product manager'],
        acl: 'Other'
      }
    }]
  }, {
    path: 'graphics-new',
    hidden: true,
    component: function component() {
      return import('@/views/graphics/components/wb');
    },
    name: 'Wall Bay Graphic Editor',
    meta: {
      title: 'wb-graphic-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'products',
    component: function component() {
      return import('@/views/products/index');
    },
    meta: {
      title: 'wb-products',
      icon: 'makeup',
      noCache: true,
      roles: ['product manager']
    },

    /* ghd */
    children: [{
      path: '',
      query: {
        tag: 'Stylers'
      },
      //component: () => import('@/views/products/index'),
      name: 'Stylers',
      meta: {
        title: 'Stylers',
        noCache: true,
        roles: ['product manager'],
        acl: 'Stylers'
      }
    }, {
      path: '',
      query: {
        tag: 'Hairdryers'
      },
      //component: () => import('@/views/products/index'),
      name: 'Hairdryers',
      meta: {
        title: 'Hairdryers',
        noCache: true,
        roles: ['product manager'],
        acl: 'Hairdryers'
      }
    }, {
      path: '',
      query: {
        tag: 'Curlers'
      },
      //component: () => import('@/views/products/index'),
      name: 'Curlers',
      meta: {
        title: 'Curlers',
        noCache: true,
        roles: ['product manager'],
        acl: 'Curlers'
      }
    }, {
      path: '',
      query: {
        tag: 'Hotbrushes'
      },
      //component: () => import('@/views/products/index'),
      name: 'Hotbrushes',
      meta: {
        title: 'Hotbrushes',
        noCache: true,
        roles: ['product manager'],
        acl: 'Hotbrushes'
      }
    }, {
      path: '',
      query: {
        tag: 'Styling range'
      },
      //component: () => import('@/views/products/index'),
      name: 'Styling range',
      meta: {
        title: 'Styling range',
        noCache: true,
        roles: ['product manager'],
        acl: 'Styling range'
      }
    }, {
      path: '',
      query: {
        tag: 'Brush range'
      },
      //component: () => import('@/views/products/index'),
      name: 'Brush range',
      meta: {
        title: 'Brush range',
        noCache: true,
        roles: ['product manager'],
        acl: 'Brush range'
      }
    }, {
      path: '',
      query: {
        tag: 'Other'
      },
      //component: () => import('@/views/products/index'),
      name: 'Other',
      meta: {
        title: 'Other',
        noCache: true,
        roles: ['product manager'],
        acl: 'WallBay.Product.Other'
      }
    }]
  }, {
    path: 'product-editor',
    hidden: true,
    component: function component() {
      return import('@/views/products/components/product');
    },
    name: 'Wall Bay Product Editor',
    meta: {
      title: 'wb-product-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'inserts',
    component: function component() {
      return import('@/views/inserts/index');
    },
    meta: {
      title: 'accessories',
      icon: 'ice-cube-tray',
      noCache: true,
      roles: ['product manager']
    },

    /* ghd */
    children: [{
      path: '',
      query: {
        tag: 'Graphic holders'
      },
      //component: () => import('@/views/inserts/index'),
      name: 'Graphic holders',
      meta: {
        title: 'Graphic holders',
        noCache: true,
        roles: ['product manager'],
        acl: 'graphics holders'
      }
    }, {
      path: '',
      query: {
        tag: 'Product displays'
      },
      //component: () => import('@/views/inserts/index'),
      name: 'Product displays',
      meta: {
        title: 'Product displays',
        noCache: true,
        roles: ['product manager'],
        acl: 'product displays'
      }
    }, {
      path: '',
      query: {
        tag: 'Other'
      },
      //component: () => import('@/views/inserts/index'),
      name: 'Other',
      meta: {
        title: 'Other',
        noCache: true,
        roles: ['product manager'],
        acl: 'AccessoryOther'
      }
    }]
  }, {
    path: 'insert-editor',
    hidden: true,
    component: function component() {
      return import('@/views/inserts/insert-editor');
    },
    name: 'Accessory Editor',
    meta: {
      title: 'accessory-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'bases',
    component: function component() {
      return import('@/views/bases/index');
    },
    name: 'Backgrounds',
    meta: {
      title: 'bases',
      icon: 'background',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'base-editor',
    hidden: true,
    component: function component() {
      return import('@/views/bases/editor');
    },
    name: 'Base Editor',
    meta: {
      title: 'base-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'components',
    component: function component() {
      return import('@/views/components/index');
    },
    name: 'Components',
    meta: {
      title: 'components',
      icon: 'work-tray',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'components-new',
    hidden: true,
    component: function component() {
      return import('@/views/components/components/componentEditor');
    },
    name: 'Component Editor',
    meta: {
      title: 'component-editor',
      icon: 'table',
      noCache: true
    }
  }, {
    path: 'trays',
    component: function component() {
      return import('@/views/trays/index');
    },
    name: 'Trays',
    meta: {
      title: 'trays',
      icon: 'data',
      noCache: true,
      roles: ['product manager']
    }
  }, {
    path: 'trays-new',
    hidden: true,
    component: function component() {
      return import('@/views/trays/components/trayEditor');
    },
    name: 'Tray Editor',
    meta: {
      title: 'tray-editor',
      icon: 'data',
      noCache: true
    }
  }, {
    path: 'planograms',
    component: function component() {
      return import('@/views/planograms/index');
    },
    name: 'Wall Bays',
    meta: {
      title: 'wallbays',
      icon: 'construction-and-tools',
      noCache: true,
      roles: ['product manager', 'sales manager']
    }
  }, {
    path: 'planograms-new',
    hidden: true,
    component: function component() {
      return import('@/views/planograms/components/planogramEditor');
    },
    name: 'Wall Bay Editor',
    meta: {
      title: 'wallbay-editor',
      icon: 'component',
      noCache: true
    }
  }]
}];