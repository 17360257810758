import "core-js/modules/es.function.name";
var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  language: function language(state) {
    return state.app.language;
  },
  size: function size(state) {
    return state.app.size;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  email: function email(state) {
    return state.user.email;
  },
  introduction: function introduction(state) {
    return state.user.introduction;
  },
  status: function status(state) {
    return state.user.status;
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  acl: function acl(state) {
    return state.user.acl;
  },
  setting: function setting(state) {
    return state.user.setting;
  },
  org: function org(state) {
    return state.user.org;
  },
  user: function user(state) {
    return state.user._id;
  },
  currOrgId: function currOrgId(state) {
    return state.currentOrg.org;
  },
  currOrgName: function currOrgName(state) {
    return state.currentOrg.name;
  },
  currOrgLogo: function currOrgLogo(state) {
    return state.currentOrg.logo;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  addRouters: function addRouters(state) {
    return state.permission.addRouters;
  },
  errorLogs: function errorLogs(state) {
    return state.errorLog.logs;
  }
};
export default getters;