import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.keys";
import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import path from "path";
import { generateTitle } from "@/utils/i18n";
import { isExternal } from "@/utils";
import Item from "./Item";
import AppLink from "./Link";
import FixiOSBug from "./FixiOSBug";
import { mapGetters } from 'vuex';
export default {
  name: "SidebarItem",
  components: {
    Item: Item,
    AppLink: AppLink
  },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  data: function data() {
    return {
      onlyOneChild: null,
      ghdGraphicTags: ["GHD CORE RANGE", "AW LIMITED EDITION", "PINK LIMITED EDITION", "XMAS LIMITED EDITION", "H1 NPD", "H2 NPD", "SEASON SALES", "OTHER"],
      ghdProductTags: ["STYLERS", "HAIRDRYERS", "CURLERS", "HOTBRUSHES", "STYLING RANGE", "BRUSH RANGE"],
      ghdAccessoryTags: ["GRAPHIC HOLDERS", "PRODUCT DISPLAYS"]
    };
  },
  computed: _objectSpread({}, mapGetters(["currOrgName"])),
  methods: {
    hasOneShowingChild: function hasOneShowingChild(children, parent) {
      var _this = this;

      var showingChildren = children.filter(function (item) {
        if (item.hidden) {
          return false;
        } else {
          // Temp set(will be used if only has one showing child)
          _this.onlyOneChild = item;
          return true;
        }
      }); // When there is only one child router, the child router is displayed by default

      if (showingChildren.length === 1) {
        return true;
      } // Show parent if there are no child router to display


      if (showingChildren.length === 0) {
        this.onlyOneChild = _objectSpread({}, parent, {
          path: "",
          noShowingChildren: true
        });
        return true;
      }

      return false;
    },
    resolvePath: function resolvePath(item) {
      if (this.isExternalLink(item.path)) {
        return item.path;
      }

      var query = "";

      if (item.query) {
        query = "?";
        var keys = Object.keys(item.query);

        for (var i = 0; i < keys.length; i++) {
          query = query + keys[i] + "=" + item.query[keys[i]];

          if (i != keys.length - 1) {
            query = query + "&";
          }
        }
      }

      return path.resolve(this.basePath, item.path) + query;
    },
    isExternalLink: function isExternalLink(routePath) {
      return isExternal(routePath);
    },
    generateTitle: generateTitle
  }
};