import Cookies from "js-cookie";
var TokenKey = "idg-token";
export function getToken() {
  return Cookies.get(TokenKey);
}
export function setToken(token, expire) {
  // HIDEME: console.log(`setToken ${token}`);
  return Cookies.set(TokenKey, token, {
    expires: expire
  });
}
export function removeToken() {
  return Cookies.remove(TokenKey);
}