import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.function.name";
import "core-js/modules/es.string.trim";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { generateTitle } from "@/utils/i18n";
import pathToRegexp from "path-to-regexp";
export default {
  data: function data() {
    return {
      levelList: null
    };
  },
  watch: {
    $route: function $route() {
      this.getBreadcrumb();
    }
  },
  created: function created() {
    this.getBreadcrumb();
  },
  methods: {
    generateTitle: generateTitle,
    getBreadcrumb: function getBreadcrumb() {
      var matched = this.$route.matched.filter(function (item) {
        return item.name;
      });
      var first = matched[0];

      if (first && first.name.trim().toLocaleLowerCase() !== "Dashboard".toLocaleLowerCase()) {
        matched = [{
          path: "/dashboard",
          meta: {
            title: "dashboard"
          }
        }].concat(matched);
      }

      this.levelList = matched.filter(function (item) {
        return item.meta && item.meta.title && item.meta.breadcrumb !== false;
      });
    },
    pathCompile: function pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      var params = this.$route.params;
      var toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink: function handleLink(item) {
      var redirect = item.redirect,
          path = item.path;

      if (redirect) {
        this.$router.push(redirect);
        return;
      }

      this.$router.push(this.pathCompile(path));
    }
  }
};