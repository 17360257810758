import "core-js/modules/es.function.name";
import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/Breadcrumb";
import { fetchSingleOrg, fetchOrgList } from "@/api/orgs";
import Hamburger from "@/components/Hamburger";
import ErrorLog from "@/components/ErrorLog";
import Screenfull from "@/components/Screenfull";
import SizeSelect from "@/components/SizeSelect";
import LangSelect from "@/components/LangSelect";
import Driver from "driver.js"; // import driver.js

import "driver.js/dist/driver.min.css"; // import driver.js css

import steps from "../../guide/defineSteps";
import stepsAdminDashboard from "../../guide/defineStepsAdminDashboard";
import stepsUsers from "../../guide/defineStepsUsers";
import stepsOrgs from "../../guide/defineStepsOrgs";
import stepsListing from "../../guide/defineStepsListing";
import stepsEditor from "../../guide/defineStepsEditor";
import stepsProfile from "../../guide/defineStepsProfile";
import stepsCompliance from "../../guide/defineStepsCompliance";
import dysonIcon from "@/assets/images/dyson-icon.jpg";
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger,
    ErrorLog: ErrorLog,
    Screenfull: Screenfull,
    SizeSelect: SizeSelect,
    LangSelect: LangSelect
  },
  data: function data() {
    return {
      simulateOrgs: [],
      dysonIcon: dysonIcon,
      driver: null
    };
  },
  computed: _objectSpread({}, mapGetters(["sidebar", "name", "avatar", "device", "org", "currOrgId", "email"])),
  created: function created() {
    var _this = this;

    fetchSingleOrg(this.org).then(function (res) {
      if (res.data.name === "IDesignGroup") {
        fetchOrgList().then(function (res) {
          _this.simulateOrgs = res.data.data;
          _this.godaccount = true;
        });
      }
    });
    this.driver = new Driver({
      className: "driver"
    });
  },
  methods: {
    guide: function guide() {
      var stepper = steps;

      switch (this.$route.meta.title) {
        case "Dashboard":
          stepper = stepsAdminDashboard;
          break;

        case "User Manager":
          stepper = stepsUsers;
          break;

        case "Organisations":
          stepper = stepsOrgs;
          break;

        case "Graphics":
          stepper = stepsListing;
          break;

        case "Products":
          stepper = stepsListing;
          break;

        case "Accessories":
          stepper = stepsListing;
          break;

        case "Components":
          stepper = stepsListing;
          break;

        case "Trays":
          stepper = stepsListing;
          break;

        case "Wall Bays":
          stepper = stepsListing;
          break;

        case "Tile Bases":
          stepper = stepsListing;
          break;

        case "Tiles (Simple)":
          stepper = stepsListing;
          break;

        case "Tiles (Complex)":
          stepper = stepsListing;
          break;

        case "Counter Unit 1":
          stepper = stepsListing;
          break;

        case "Counter Unit 2":
          stepper = stepsListing;
          break;

        case "Floors":
          stepper = stepsListing;
          break;

        case "Boxes":
          stepper = stepsListing;
          break;

        case "Props":
          stepper = stepsListing;
          break;

        case "Plinths":
          stepper = stepsListing;
          break;

        case "Plinth Builder":
          stepper = stepsListing;
          break;

        case "Store Builder":
          stepper = stepsListing;
          break;

        case "Graphic Editor":
          stepper = stepsEditor;
          break;

        case "Product Editor":
          stepper = stepsEditor;
          break;

        case "Accessory Editor":
          stepper = stepsEditor;
          break;

        case "Graphic Editor":
          stepper = stepsEditor;
          break;

        case "Component Editor":
          stepper = stepsEditor;
          break;

        case "Tray Editor":
          stepper = stepsEditor;
          break;

        case "Planogram Editor":
          stepper = stepsEditor;
          break;

        case "Tile Base Editor":
          stepper = stepsEditor;
          break;

        case "Tile (Simple) Editor":
          stepper = stepsEditor;
          break;

        case "Tile (Complex) Editor":
          stepper = stepsEditor;
          break;

        case "Counter Unit 1 Editor":
          stepper = stepsEditor;
          break;

        case "Counter Unit 2 Editor":
          stepper = stepsEditor;
          break;

        case "Floor Editor":
          stepper = stepsEditor;
          break;

        case "Box Editor":
          stepper = stepsEditor;
          break;

        case "Prop Editor":
          stepper = stepsEditor;
          break;

        case "Plinth Editor":
          stepper = stepsEditor;
          break;

        case "Plinth Builder Editor":
          stepper = stepsEditor;
          break;

        case "Store Editor":
          stepper = stepsEditor;
          break;

        case "My Profile":
          stepper = stepsProfile;
          break;

        case "Compliance Order Editor":
          stepper = stepsCompliance;
          break;
      }

      this.driver.defineSteps(stepper);
      this.driver.start();
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch("toggleSideBar");
    },
    className: function className(id) {
      if (id === this.currOrgId) {
        return "bold";
      } else {
        return "normal";
      }
    },
    simulate: function simulate(orgId) {
      this.$store.dispatch("updateCurrentOrgId", orgId).then(function () {// HIDEME: console.log(this.currOrgId)
      });
    },
    logout: function logout() {
      this.$store.dispatch("FedLogOut").then(function () {
        location.reload(); // In order to re-instantiate the vue-router object to avoid bugs
      });
    }
  }
};