var stepsCompliance = [{
  element: '#download-pdf',
  popover: {
    title: 'Download PDF',
    description: 'Click to download compliance PDF.',
    position: 'top'
  }
}, {
  element: '#upload-pdf',
  popover: {
    title: 'Upload PDF',
    description: 'Click to upload the signed compliance PDF. A green tick will appear if a file had been uploaded',
    position: 'top'
  }
}, {
  element: '#upload-image',
  popover: {
    title: 'Upload Images',
    description: 'Click to upload images of the store.',
    position: 'top'
  }
}];
export default stepsCompliance;