import request from "@/utils/request";
export function fetchUserList(params) {
  return request({
    url: "/users",
    method: "get",
    params: params
  });
}
export function fetchSingleUser(id) {
  return request({
    url: "/users/".concat(id),
    method: "get"
  });
}
export function createUser(data) {
  return request({
    url: "/users",
    method: "post",
    data: data
  });
}
export function updateUser(id, data) {
  return request({
    url: "/users/".concat(id),
    method: "patch",
    data: data
  });
}
export function deleteUser(id) {
  return request({
    url: "/users/".concat(id),
    method: "delete",
    id: id
  });
}