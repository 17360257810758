var steps = [{
  element: '.hamburger-container',
  popover: {
    title: 'Hamburger',
    description: 'Open && Close sidebar',
    position: 'bottom'
  }
}, {
  element: '.breadcrumb-container',
  popover: {
    title: 'Breadcrumb',
    description: 'Indicate the current page location',
    position: 'bottom'
  }
}, {
  element: '#profile-links',
  popover: {
    title: 'Quick Links',
    description: 'Access your profile to change your password, simulate other organisations, and logout.',
    position: 'left'
  }
}, {
  element: '#showguide-guide',
  popover: {
    title: 'Guide',
    description: 'Step through the key elements of a page. Click here if you\'re unsure of how something functions.',
    position: 'left'
  }
}, {
  element: '.international-icon',
  popover: {
    title: 'Switch language',
    description: 'Switch the system language',
    position: 'left'
  }
}, {
  element: '.screenfull',
  popover: {
    title: 'Screenfull',
    description: 'Bring the page into fullscreen',
    position: 'left'
  }
}];
export default steps;