import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang

import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang

import elementEsLocale from "element-ui/lib/locale/lang/es"; // element-ui lang

import enLocale from "./en";
import zhLocale from "./zh";
import esLocale from "./es";
import axios from 'axios';
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale, {}, elementEnLocale),
  zh: _objectSpread({}, zhLocale, {}, elementZhLocale),
  es: _objectSpread({}, esLocale, {}, elementEsLocale)
};
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: Cookies.get("language") || "en",
  // set locale messages
  messages: messages
}); //axios.get('https://flintpro-viz.s3-ap-southeast-2.amazonaws.com/i18n/translations.json').then(res => {})

import translationsJSON from './global.json';
i18n.setLocaleMessage('en', _objectSpread({}, elementEnLocale, {}, translationsJSON.en)); //i18n.setLocaleMessage('es', { ...elementEsLocale, ...translationsJSON.es })

export default i18n;