var stepsProfile = [{
  element: '#avatar',
  popover: {
    title: 'Avatar',
    description: 'Hover to edit avatar',
    position: 'right'
  }
}, {
  element: '#change-name',
  popover: {
    title: 'Name',
    description: 'Click to edit name',
    position: 'right'
  }
}];
export default stepsProfile;