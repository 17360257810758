var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened
        }
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _c("el-button", {
            staticClass: "guide right-menu-item",
            staticStyle: { "font-size": "24px", "vertical-align": "10px" },
            attrs: {
              id: "showguide-guide",
              icon: "el-icon-question",
              type: "text"
            },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.guide($event)
              }
            }
          }),
          _c("lang-select", {
            staticClass: "international right-menu-item",
            attrs: { id: "language-selector" }
          }),
          _c(
            "el-tooltip",
            {
              attrs: {
                effect: "dark",
                content: "Full Screen",
                placement: "bottom"
              }
            },
            [
              _c("screenfull", {
                staticClass: "screenfull right-menu-item",
                attrs: { id: "fullscreen-selector" }
              })
            ],
            1
          ),
          _c(
            "el-dropdown",
            {
              staticClass: "avatar-container right-menu-item",
              attrs: { trigger: "click", id: "profile-links" }
            },
            [
              _c("div", { staticClass: "avatar-wrapper" }, [
                _c("img", {
                  staticClass: "user-avatar",
                  attrs: { src: _vm.avatar ? _vm.avatar : _vm.dysonIcon }
                }),
                _c("i", { staticClass: "el-icon-caret-bottom" })
              ]),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                [
                  _c("span", { staticClass: "display-name" }, [
                    _vm._v(_vm._s(_vm.email))
                  ]),
                  _c(
                    "router-link",
                    { attrs: { to: "/" } },
                    [
                      _c("el-dropdown-item", [
                        _vm._v(_vm._s(_vm.$t("navbar.dashboard")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "router-link",
                    { attrs: { to: "/profile" } },
                    [_c("el-dropdown-item", [_vm._v("My Profile")])],
                    1
                  ),
                  _vm.simulateOrgs.length > 0
                    ? _c(
                        "el-dropdown-item",
                        { attrs: { divided: "", disabled: "" } },
                        [
                          _c("span", { staticStyle: { display: "block" } }, [
                            _vm._v("Simulate Organisations")
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._l(_vm.simulateOrgs, function(org) {
                    return _c(
                      "el-dropdown-item",
                      {
                        key: org.name,
                        attrs: { disabled: org._id === _vm.currOrgId }
                      },
                      [
                        _c(
                          "span",
                          {
                            class: _vm.className(org._id),
                            staticStyle: { display: "block" },
                            on: {
                              click: function($event) {
                                return _vm.simulate(org._id)
                              }
                            }
                          },
                          [_vm._v(_vm._s(org.name))]
                        )
                      ]
                    )
                  }),
                  _c("el-dropdown-item", { attrs: { divided: "" } }, [
                    _c(
                      "span",
                      {
                        staticStyle: { display: "block" },
                        on: { click: _vm.logout }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("navbar.logOut")) + " ")]
                    )
                  ])
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }