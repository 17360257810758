import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import "/opt/atlassian/pipelines/agent/build/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.array.iterator.js";
import "/opt/atlassian/pipelines/agent/build/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.js";
import "/opt/atlassian/pipelines/agent/build/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.object.assign.js";
import "/opt/atlassian/pipelines/agent/build/node_modules/@vue/babel-preset-app/node_modules/core-js/modules/es.promise.finally.js";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Cookies from "js-cookie";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets

import "@/styles/index.scss"; // global css

import "./icons"; // icon

import VueDraggableResizable from "vue-draggable-resizable"; // optionally import default styles

import "vue-draggable-resizable/dist/VueDraggableResizable.css"; // import "./mock";

import _i18n from "./lang"; // Internationalization

import "./errorLog"; // error log

import "./permission"; // permission control

import * as filters from "./filters"; // global filters
// Vue.use(Element);

Vue.use(Element, {
  size: Cookies.get("size") || "medium",
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
}); // register global utility filters.

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.component("vue-draggable-resizable", VueDraggableResizable);
Vue.config.productionTip = false;
new Vue({
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app");