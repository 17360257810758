var stepsListing = [{
  element: '#add-new',
  popover: {
    title: 'Add New Object',
    position: 'right'
  }
}, {
  element: '#search-filter',
  popover: {
    title: 'Search && Filter',
    description: 'Search and filter objects',
    position: 'bottom'
  }
}, {
  element: '#sort-objects',
  popover: {
    title: 'Sort',
    description: 'Sort objects in desired order',
    position: 'left'
  }
}, {
  element: '#all-tag0',
  popover: {
    title: 'Tags',
    description: 'Hover to see all tags',
    position: 'top'
  }
}, {
  element: '#edit0',
  popover: {
    title: 'Edit Object',
    position: 'top'
  }
}, {
  element: '#delete0',
  popover: {
    title: 'Delete Object',
    position: 'top'
  }
}, {
  element: '#download-pdf0',
  popover: {
    title: 'Download PDF',
    description: 'Download the object\'s PDF',
    position: 'top'
  }
}, {
  element: '#share0',
  popover: {
    title: 'Share Object',
    position: 'top'
  }
}, {
  element: '#clone0',
  popover: {
    title: 'Clone Object',
    position: 'top'
  }
}];
export default stepsListing;