import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from "vuex";
import SidebarItem from "./SidebarItem";
import _variables from "@/styles/variables.scss";
import dysonLogo from "@/assets/images/dyson-logo.jpg";
import logo from "@/assets/images/logo.jpeg";
export default {
  components: {
    SidebarItem: SidebarItem
  },
  data: function data() {
    return {
      logo: logo,
      dysonLogo: dysonLogo
    };
  },
  computed: _objectSpread({}, mapGetters(["permission_routers", "sidebar", "currOrgLogo"]), {
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  })
};