var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-scrollbar",
    { attrs: { "wrap-class": "scrollbar-wrapper" } },
    [
      _c(
        "el-menu",
        {
          attrs: {
            "show-timeout": 200,
            "default-active": _vm.$route.path,
            collapse: _vm.isCollapse,
            "background-color": _vm.variables.menuBg,
            "text-color": _vm.variables.menuText,
            "active-text-color": _vm.variables.menuActiveText,
            mode: "vertical"
          }
        },
        [
          _c("el-menu-item", { staticClass: "logo", attrs: { index: "99" } }, [
            _c("img", {
              staticClass: "login-logo",
              attrs: { src: _vm.currOrgLogo ? _vm.currOrgLogo : _vm.dysonLogo }
            })
          ]),
          _vm._l(_vm.permission_routers, function(route) {
            return _c("sidebar-item", {
              key: route.path,
              staticStyle: { "z-index": "1" },
              attrs: { item: route, "base-path": route.path }
            })
          }),
          _c(
            "el-menu-item",
            { staticClass: "logo-bottom", attrs: { index: "99" } },
            [
              _c("span", { staticClass: "poweredby" }, [_vm._v("Powered By")]),
              _c("img", { staticClass: "login-logo", attrs: { src: _vm.logo } })
            ]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }