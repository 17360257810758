//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  computed: {
    language: function language() {
      return this.$store.getters.language;
    }
  },
  methods: {
    handleSetLanguage: function handleSetLanguage(lang) {
      /*this.$i18n.locale = lang;
      this.$store.dispatch("setLanguage", lang);
      this.$message({
        message: "Switch Language Success",
        type: "success"
      });*/
    }
  }
};