//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { Navbar, Sidebar, AppMain } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
export default {
  name: "Layout",
  components: {
    Navbar: Navbar,
    Sidebar: Sidebar,
    AppMain: AppMain
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar: function sidebar() {
      return this.$store.state.app.sidebar;
    },
    device: function device() {
      return this.$store.state.app.device;
    },
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    }
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch("closeSideBar", {
        withoutAnimation: false
      });
    }
  }
};