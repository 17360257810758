import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.includes";
import "core-js/modules/es.array.some";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.includes";
import "core-js/modules/web.dom-collections.for-each";
import _objectSpread from "/opt/atlassian/pipelines/agent/build/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import { asyncRouterMap, constantRouterMap, asyncGHDMap } from "@/router"; //import { asyncGHDMap } from "@/router/ghd.js";

import { fetchSingleOrg } from "@/api/orgs";
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */

function hasPermission(roles, route, acl, orgacl) {
  //handle admin
  if (roles.includes("admin")) {
    return true;
  } //handle non admin
  else {
      if (route.meta && route.meta.roles) {
        var access = roles.some(function (role) {
          return route.meta.roles.includes(role);
        });

        if (route.meta.acl) {
          //check of orgacl.[key] exists and access is there
          //return acl.some(acl => route.meta.acl.includes(acl)) && access
          //if orgacl access key matches acl
          var find = orgacl.find(function (x) {
            return x.access === route.meta.acl;
          });

          if (find) {
            return find.grant.some(function (x) {
              return roles.includes(x);
            });
          } else {
            return false;
          }
        } else {
          return access;
        }
      } else {
        return true;
      }
    }
}
/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */


function filterAsyncRouter(routes, roles, acl, orgacl) {
  var res = [];
  routes.forEach(function (route) {
    var tmp = _objectSpread({}, route);

    if (hasPermission(roles, tmp, acl, orgacl)) {
      if (tmp.children) {
        tmp.children = filterAsyncRouter(tmp.children, roles, acl, orgacl);
      }

      res.push(tmp);
    }
  });
  return res;
}

var permission = {
  state: {
    routers: [],
    addRouters: []
  },
  mutations: {
    SET_ROUTERS: function SET_ROUTERS(state, routers) {
      state.addRouters = routers;
      state.routers = constantRouterMap.concat(routers);
    }
  },
  actions: {
    GenerateRoutes: function GenerateRoutes(_ref, data) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // HIDEME: console.log("roles arr " + data.roles);
        var roles = data.roles;

        if (!roles) {
          roles = [data.role];
        }

        var acl = data.acl;
        var org = data.org;
        var routerMap = asyncRouterMap; //combined ghd if org matches

        if (org === '5f0d20391ad7a10011168387') {
          routerMap = asyncGHDMap;
        }

        return fetchSingleOrg('5f0d20391ad7a10011168387').then(function (res) {
          var data = res.data;
          var orgacl = data.acl;
          var accessedRouters = filterAsyncRouter(routerMap, roles, acl, orgacl);
          /*if (orgacl) {
            accessedRouters = filterAsyncRouter(routerMap, roles, acl, orgacl);
          }*/

          commit("SET_ROUTERS", accessedRouters);
          return resolve();
        });
      });
    }
  }
};
export default permission;