var stepsAdminDashboard = [{
  element: '.hamburger-container',
  popover: {
    title: 'Hamburger',
    description: 'Open && Close sidebar.',
    position: 'bottom'
  }
}, {
  element: '.breadcrumb-container',
  popover: {
    title: 'Breadcrumb',
    description: 'Indicate the current page location.',
    position: 'bottom'
  }
}, {
  element: '#profile-links',
  popover: {
    title: 'Quick Links',
    description: 'Access your profile to change your password, simulate other organisations, and logout.',
    position: 'left'
  }
}, {
  element: '#showguide-guide',
  popover: {
    title: 'Guide',
    description: 'Step through the key elements of a page. Click here if you\'re unsure of how something functions.',
    position: 'left'
  }
}, {
  element: '#language-selector',
  popover: {
    title: 'Language',
    description: 'Switch the system language (not currently supported).',
    position: 'left'
  }
}, {
  element: '#fullscreen-selector',
  popover: {
    title: 'Fullscreen',
    description: 'Bring the page into fullscreen.',
    position: 'left'
  }
}, {
  element: '#activity-chart',
  popover: {
    title: 'Planogram Activity',
    description: 'Planogram activity for the past 7 days.',
    position: 'bottom'
  }
}, {
  element: '#object-number',
  popover: {
    title: 'Object Number',
    description: 'The number of objects that currently exist for each type of planogram.',
    position: 'top'
  }
}, {
  element: '#org-info',
  popover: {
    title: 'Organisations',
    description: 'The number of users and their role for each organisation.',
    position: 'right'
  }
}, {
  element: '#status-chart',
  popover: {
    title: 'Workflow Status',
    description: 'The current status distribution for each planogram type and their corresponding objects.',
    position: 'left'
  }
}, {
  element: '#stock-chart',
  popover: {
    title: 'Stock On Hand',
    description: 'The number of stock on hand for each planogram type and their objects',
    position: 'top'
  }
}];
export default stepsAdminDashboard;