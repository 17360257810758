var stepsEditor = [
/*{
  element: '#detail-tab',
  popover: {
    title: 'Details',
    description: 'Edit object details e.g. add tags, change status, upload files.',
    position: 'bottom'
  }
},
{
  element: '#planogram-tab',
  popover: {
    title: 'Planogram Editor',
    description: 'Create and edit object planogram.',
    position: 'bottom'
  }
},
{
  element: '#planogram-tab0',
  popover: {
    title: 'Top Shelf',
    description: 'Create and edit counter unit top shelf.',
    position: 'bottom'
  }
},
{
  element: '#planogram-tab1',
  popover: {
    title: 'Middle Shelf',
    description: 'Create and edit counter unit middle shelf.',
    position: 'bottom'
  }
},
{
  element: '#planogram-tab2',
  popover: {
    title: 'Bottom Shelf',
    description: 'Create and edit counter unit bottom shelf.',
    position: 'bottom'
  }
},
{
  element: '#pdf-tab',
  popover: {
    title: 'Compliance PDF',
    description: 'Create and edit store compliance PDF.',
    position: 'bottom'
  }
},
{
  element: '#compliance-tab',
  popover: {
    title: 'Compliance Orders',
    description: 'Create, view, and edit store compliance orders.',
    position: 'bottom'
  }
},
{
  element: '#version-tab',
  popover: {
    title: 'Versions',
    description: 'View and restore previous versions of object.',
    position: 'bottom'
  }
},*/
{
  element: '#edit-asset',
  popover: {
    title: 'Edit Assets',
    description: 'Click to edit assets table.',
    position: 'right'
  }
}, {
  element: '#save-asset',
  popover: {
    title: 'Save assets',
    description: 'Click to stop editing assets table',
    position: 'right'
  }
}, {
  element: '#new-row',
  popover: {
    title: 'Add New Row',
    description: 'Add new row to asset table',
    position: 'top'
  }
}];
export default stepsEditor;