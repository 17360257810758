import request from "@/utils/request";
export function fetchOrgList() {
  return request({
    url: "/orgs",
    method: "get"
  });
}
export function fetchSingleOrg(id) {
  return request({
    url: "/orgs/".concat(id),
    method: "get"
  });
}
export function createOrg(data) {
  return request({
    url: "/orgs",
    method: "post",
    data: data
  });
}
export function updateOrg(id, data) {
  return request({
    url: "/orgs/".concat(id),
    method: "patch",
    data: data
  });
}
export function deleteOrg(id) {
  return request({
    url: "/orgs/".concat(id),
    method: "delete",
    id: id
  });
}