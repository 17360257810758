import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";
import errorLog from "./modules/errorLog";
import permission from "./modules/permission";
import tagsView from "./modules/tagsView";
import user from "./modules/user";
import currentOrg from "./modules/currentOrg";
import getters from "./getters";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    app: app,
    errorLog: errorLog,
    permission: permission,
    tagsView: tagsView,
    user: user,
    currentOrg: currentOrg
  },
  getters: getters
});
export default store;