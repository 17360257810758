import store from "@/store";
var _document = document,
    body = _document.body;
var WIDTH = 1024;
var RATIO = 3;
export default {
  watch: {
    $route: function $route(route) {
      if (this.device === "mobile" && this.sidebar.opened) {
        store.dispatch("closeSideBar", {
          withoutAnimation: false
        });
      }
    }
  },
  beforeMount: function beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  },
  mounted: function mounted() {
    var isMobile = this.isMobile();

    if (isMobile) {
      store.dispatch("toggleDevice", "mobile");
      store.dispatch("closeSideBar", {
        withoutAnimation: true
      });
    }
  },
  methods: {
    isMobile: function isMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - RATIO < WIDTH;
    },
    resizeHandler: function resizeHandler() {
      if (!document.hidden) {
        var isMobile = this.isMobile();
        store.dispatch("toggleDevice", isMobile ? "mobile" : "desktop");

        if (isMobile) {
          store.dispatch("closeSideBar", {
            withoutAnimation: true
          });
        }
      }
    }
  }
};